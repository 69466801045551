<template>
  <div :class="$style.root">
    <VForm ref="form" @submit.prevent="onSave">
      <VExpansionPanels flat multiple>
        <EmployeeDetailSection
            v-for="(section) in orderControl(fields)"
            v-bind="{...section}"
            :employee="id"
            :comments="comments"
            :readonly="readonly"
            :key="section.code"
        />
        <VExpansionPanel>
          <VExpansionPanelHeader class="title">Договоры в которых задействован сотрудник</VExpansionPanelHeader>
          <VExpansionPanelContent eager>
            <ContractList
                :items="contracts.items"
                :count="contracts.count"
                :page="page"
                :size="size"
                :loading="loading"
                :disabled="!hasRole(['CONTRAGENT', 'CONTRACT_HOLDER'], 'elkp')"
                :selected.sync="contractsSelected"
                @click:row="({ id }) => $router.push({ name: 'elkp/EmployeeDetailView', params: { employee: id }})"
            />
          </VExpansionPanelContent>
        </VExpansionPanel>
      </VExpansionPanels>
    </VForm>
    <VFooter app v-if="!readonly">
      <div class="py-3 grow">
        <VRow dense>
          <template v-if="!contractsSelected.length">
            <VCol cols="auto" class="grow">
              <VBtn class="mr-3" color="primary" depressed @click="onAgreement">Сохранить</VBtn>
              <VBtn v-if="diff" class="mr-3" color="primary" depressed @click="onSave">Применить</VBtn>
              <VBtn v-if="diff" color="secondary" depressed @click="onReset">Отмена</VBtn>
            </VCol>
            <VCol v-if="contract" cols="auto">
              <VTooltip left color="error" :disabled="!diff">
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on" class="float-right">
                    <VBtn v-if="hasRole(['CONTRAGENT', 'CONTRACT_HOLDER'], 'elkp')" :disabled="diff" color="secondary" class="mr-3" depressed @click="$router.push({ name: 'elkp/ContractLinkView', params: { employee: id } })">
                      Привязать к существующим договорам
                    </VBtn>
                    <VBtn :disabled="diff" color="primary" depressed @click="onCreate">
                      Добавить нового
                    </VBtn>
                  </div>
                </template>
                <span>Есть не сохраненные изменения в текущей анкете</span>
              </VTooltip>
            </VCol>
          </template>
          <template v-else>
            <VCol cols="auto" class="grow">
              <VBtn color="primary" depressed :loading="loading" @click="onDelete">
                Исключить из выбранных договоров
              </VBtn>
              <VBtn color="secondary" depressed class="ml-4" @click="contractsSelected = []">
                Отмена
              </VBtn>
            </VCol>
          </template>
        </VRow>
      </div>
    </VFooter>
  </div>
</template>

<script>
import { debounce, map, sortBy, values } from 'lodash-es';
import { mapGetters, mapActions } from 'vuex';
import { ROLES } from '@/store/user/enums';
import ContractList from '@/components/elkp/ContractList/ContractList';
import EmployeeDetailSection from '@/components/elkp/EmployeeDetailSection/EmployeeDetailSection';
export default {
  name: 'EmployeeDetail',
  components: {
    ContractList,
    EmployeeDetailSection,
  },
  props: {
    id: { type: String },
    values: { type: Object },
    contract: { type: String },
    comments: { type: Object },
    page: { type: Number, default: 1 },
    size: { type: Number, default: 20 },
    filter: { type: Object },
  },
  data() {
    return {
      selected: 0,
      contractsSelected: [],
      fields: JSON.parse(JSON.stringify(this.values)),
    }
  },
  computed: {
    ...mapGetters({
      hasRole: 'user/hasRole',
      contracts: 'elkp/contracts',
      loading: 'elkp/pending',
    }),
    diff() {
      return JSON.stringify(this.values) !== JSON.stringify(this.fields);
    },
    readonly() {
      return !this.hasRole([ROLES.CONTRAGENT, ROLES.CONTRACT_HOLDER], 'elkp');
    }
  },
  methods: {
    ...mapActions({
      fetchContractList: 'elkp/fetchContractList',
      fetchEmployeeDetail: 'elkp/fetchEmployeeDetail',
      fetchCommentList: 'elkp/fetchCommentList',
      updateEmployee: 'elkp/updateEmployee',
      createEmployee: 'elkp/createEmployee',
      removeEmployeeFromContract: 'elkp/removeEmployeeFromContract',
    }),
    onUpdateContracts: debounce(function() {
      this.contractsSelected = [];
      const { page, size, filter } = this;
      this.fetchContractList({ page, size, filter });
    }, 500),
    orderControl(items) {
      return sortBy(values(items), 'order');
    },
    onAgreement() {
      const id = this.id;
      const contract = this.contract;
      const values = this.fields;
      this.updateEmployee({ id, values }).then(() => {
        if (contract) this.$router.push({ name: 'elkp/ContractDetailView', params: { contract }});
        else {
          this.fetchEmployeeDetail({ id });
          this.fetchCommentList({ id });
        }
      });
    },
    onSave() {
      const id = this.id;
      const values = this.fields;
      this.updateEmployee({ id, values }).then(() => {
        this.fetchEmployeeDetail({ id });
        this.fetchCommentList({ id });
      })
    },
    onReset() {
      this.fields = JSON.parse(JSON.stringify(this.values));
    },
    onCreate() {
      const id = this.contract;
      this.createEmployee({ id }).then((employee) => {
        if (employee) this.$router.push({ name: 'elkp/EmployeeDetailView', params: { employee, contract: id }});
      });
    },
    onDelete: function() {
      const contract = map(this.contractsSelected, 'id');
      const employees = [this.id];
      this.removeEmployeeFromContract({ employees, contract }).then((result) => {
        if (result) this.onUpdateContracts();
      });
    },
  },
  watch: {
    page: function() {
      this.onUpdateContracts();
    },
    size: function() {
      this.onUpdateContracts();
    },
    filter: {
      deep: true,
      immediate: true,
      handler: function() {
        this.onUpdateContracts();
      }
    },
    values: {
      handler: function() {
        this.onReset();
      }
    }
  }
}
</script>

<style module lang="scss">
.root {
  $header-bg: #efefef;
  $border-color: #dee2e6;
  :global(.v-expansion-panel) {
    border: 1px solid $border-color;
    margin-bottom: -1px;
  }
  :global(.v-card) {
    border: 1px solid #dee2e6;
  }
}
</style>
