<template>
  <div :class="$style.root">
    <h1 v-if="$route.meta.title" class="mb-4">{{$route.meta.title}} #{{id}}</h1>
    <div v-if="contract" class="mb-4 clearfix">
      <VBtn v-if="contract === 'employee'" depressed @click="$router.push({name: 'elkp/EmployeeListView'})">
        <VIcon v-text="'mdi-arrow-left'" />
        Назад к списку сотрудников
      </VBtn>
      <VBtn v-else depressed @click="$router.push({name: 'elkp/ContractDetailView', params: { contract }})">
        <VIcon v-text="'mdi-arrow-left'" />
        Назад к договору
      </VBtn>
    </div>
    <div :class="$style.inner">
      <VProgressLinear v-if="pending" absolute indeterminate color="primary darken-2" />
      <template v-if="current">
        <EmployeeDetail
            :id="current.id"
            :contract="contract"
            :values="current.values"
            :comments="commentsInCurrent"
            :page="page"
            :size="size"
            :filter="filter"
        />
      </template>
    </div>
    <VDialog :value="!!question" max-width="900" scrollable persistent>
      <CommentCard :items="commentsInDialog">
        <template v-slot:top>
          <VToolbar flat dark color="primary">
            <VToolbarTitle class="px-2">Замечания</VToolbarTitle>
            <VSpacer/>
            <VBtn icon dark @click="$router.push({ name: 'elkp/EmployeeDetailView', params: { contract, employee: id, question: null }})">
              <VIcon>mdi-close</VIcon>
            </VBtn>
          </VToolbar>
        </template>
        <template v-slot:bottom>
          <VCardActions>
            <div v-if="isReviewer" class="flex-grow-1 px-2 py-4 border-top-1">
              <CommentForm @submit="onSubmitComment" />
            </div>
          </VCardActions>
        </template>
      </CommentCard>
    </VDialog>
  </div>
</template>

<script>
import { get, mapValues, filter } from 'lodash-es';
import { mapActions, mapGetters } from 'vuex';
import { ROLES } from '@/store/user/enums';
import CommentForm from '@/components/general/CommentForm/CommentForm';
import CommentCard from '@/components/elkp/CommentCard/CommentCard';
import EmployeeDetail from '@/components/elkp/EmployeeDetail/EmployeeDetail';
export default {
  name: 'EmployeeDetailView',
  components: {
    CommentCard,
    CommentForm,
    EmployeeDetail,
  },
  props: {
    id: { type: String },
    contract: { type: String },
    question: { type: String },
    page: { type: Number, default: 1 },
    size: { type: Number, default: 20 },
    filter: { type: Object },
  },
  computed: {
    ...mapGetters({
      pending: 'elkp/pending',
      current: 'elkp/employee',
      commentsInCurrent: 'elkp/comments',
      questionsByCode: 'elkp/questionsByCode',
      hasRole: 'user/hasRole',
    }),
    isReviewer() {
      return this.hasRole(ROLES.REVIEWER, 'elkp');
    },
    commentsInDialog() {
      const id = get(this.questionsByCode, [this.question, 'question']);
      return get(this.commentsInCurrent, id, []);
    },
    commentsCountInCurrent() {
      return mapValues(this.commentsInCurrent, items => ({
        count: items.length,
        actual: filter(items, { actual: true }).length,
      }))
    }
  },
  methods: {
    ...mapActions({
      fetchEmployeeDetail: 'elkp/fetchEmployeeDetail',
      fetchCommentList: 'elkp/fetchCommentList',
      createComment: 'elkp/createComment',
    }),
    onSubmitComment: function(message) {
      const id = get(this.questionsByCode, [this.question, 'question']);
      const employee= this.id;
      this.createComment({ id, employee, message  }).then(() => {
        const id = this.id;
        this.fetchCommentList({ id });
      });
    },
  },
  watch: {
    id: {
      immediate: true,
      handler(id) {
        if (id) {
          this.fetchEmployeeDetail({ id });
          this.fetchCommentList({ id });
        }
      }
    },
  },
}
</script>

<style module lang="scss">
.root {
  .inner {
    position: relative;
  }
}
</style>
