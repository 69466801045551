<template>
  <VCard tile>
    <slot name="top" />
    <VCardText class="pt-5">
      <div v-if="!items.length">Комментарии отсутствуют</div>
      <div v-if="items.length" :class="$style.list">
        <div v-for="comment in comments" :class="$style.item" :key="comment.id">
          <Comment v-bind="comment" />
        </div>
      </div>
    </VCardText>
    <slot name="bottom" />
  </VCard>
</template>

<script>
import { reverse } from 'lodash-es';
import Comment from '@/components/general/Comment/Comment';
export default {
  name: 'CommentCard',
  components: {
    Comment,
  },
  props: {
    items: { type: Array, default: () => [] },
  },
  computed: {
    comments() {
      return reverse(this.items);
    }
  }
}
</script>

<style module lang="scss">
.root {}
.item {
  &:not(:last-child) {
    margin-bottom: 10px;
  }
}
</style>
